import { Button, Text } from "@mantine/core";
import React, { useState } from "react";
import styles from "./Section2.module.scss";
import { useNavigate } from "react-router-dom";
import obligationsicon from "./obligationsmanager.png";
import recoveriesicon from "./recoveriesicon.png";
import agreementsicon from "./agreementsmanager.png";


const activeModules = [
  {
    id: 1,
    name: "agreements manager",
    icon: "https://cdn1.iconfinder.com/data/icons/digital-banking-vol-1/80/contract__partnership__agreement__handshake_-512.png",
    url: "/app/agreements_manager/"
  },
  {
    id: 2,
    name: "obligations manager",
    icon: "https://png.pngtree.com/png-clipart/20230424/original/pngtree-completed-task-line-icon-png-image_9093625.png",
    url: "https://obdemoapp.truecounsel.in/login"
    // url: "https://obligationapp.truecounsel.in/login"
    // url: "https://wappobligations.truecounsel.in/login"
  },
  {
    id: 3,
    name: "recoveries manager",
    icon: "https://cdn-icons-png.flaticon.com/512/3535/3535035.png",
    url: "https://recoveryappdemo.truecounsel.in/app/login",
    // url: "https://wappsysrecoveries.truecounsel.in/app/login",
    // url: "https://recoveryapp.truecounsel.in/app/login"
  }
]

function Section2() {
  const [state, setState] = useState(false);
   const navigate = useNavigate();

  //  const handleAgreementsManagerClick = () => {
  //   // console.log('press');
  //   window.open("/app/agreements_manager/", "_blank");
  //  };

  // //  const handleAgreementsManagerClick2 = () => {
  // //   console.log('press');
  // //    navigate("/app/agreements_manager/notifications");
  // //  };
  //  const handleNotificationsClick = () => {
  //    navigate("/app/agreements_manager/notifications");
  //  };
   
   const handleClick =(url)=>{
    window.open(`${url}`, "_blank");
   }
  return (
    <div>
      <div>
        <div className={`mb-10 ${styles.sectionHeading}`}>
        hi-tech | hi-touch legal solutions
        </div>
        <div
          className={`mt-3 grid grid-cols-${activeModules.length} gap-2 mb-5 justify-center items-center `}
        >
          {activeModules.map(module =>
            <div
            style={{
              // border: "2px solid red",
              position: "relative",
            }}
            className="mx-auto"
            key={module.id}
          >
            <button onClick={()=>handleClick(module.url)}>
            <div >
            <div style={{paddingLeft: "30%", paddingRight: "30%"}}>
            <img className={`${styles.img}`}  src={module.icon}/>
            </div>
            <Text className="text-center mt-2 text-2xl font-bold "
            style={{
              color: "#46BDE1"
            }}>{module.name}</Text>
            </div>
            </button>
          </div>
        )}
          
          
            {/* <Button
            className={`${styles.buttonCommon}`}
            variant="outline"
            onClick={handleDocumentsManagerClick}
            
            style={
              !state
                ? { background: "#46BDE1", color: "white" }
                : { background: "white", color: "#46BDE1" }
            }
            >
              documents manager
            </Button> */}
          {/* <Button
            className={`${styles.buttonCommon} `}
            variant="outline"
            style={{
              background: "white",
              color: "#46BDE1",
              border: "2px solid #46BDE1",
              
            }}
            disabled
          >
            <div style={{width: "auto", display: "flex", flexDirection: "column" }}>
              <div style={{ display: "block", textAlign: "center" }}>
                disputes manager
              </div>
              <div
                style={{
                  display: "block",
                  fontSize: "15px",
                  textAlign: "center",
                  fontFamily: "sans-serif"
                }}
              >
                coming soon
              </div>
            </div>
          </Button> */}
        </div>
        <div className="text-4xl font-semibold mt-10 pt-10 text-center ">
          manage your business hygiene with ease
        </div>
      </div>
    </div>
  );
}
export default Section2;
